import { API } from '../utils/config'

export function SubmitQuiz(data) {
    return API.post(`/quiz/submit/`, data)
}

export function EditViewQuiz(id, token, data) {
    if (data) {
        return API.patch(`/quiz/edit/${id}/`, data, { headers: { Authorization: 'Token ' + token } })
    } else {
        return API(`/quiz/edit/${id}/`, { headers: { Authorization: 'Token ' + token } })
    }
}

export function GetQuiz(token, id) {
    return API(`/quiz/view/${id}/`, { headers: { Authorization: 'Token ' + token } })
}

export function GetOptions() {
    return API(`/quiz/options/`)
}
