import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import { withRouter, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Spinner } from '@blueprintjs/core'
import { withContext } from '../../context/withContext'
import { Modal, ModalBody } from 'reactstrap'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

function Menu(props) {
    const [modal, toggle] = useState(false)

    const handleLogout = () => {
        toggle(true)
        cookies.remove('token')
        let state = {
            token: null,
            isLogged: false,
            user: {},
        }
        setTimeout(() => {
            toggle(false)
            props.updateContextVal(state)
        }, 2000)
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h4>Mi Cuenta</h4>
                    <NavLink activeClassName="active" exact activeClassName="active" to={`/mi-cuenta`}>
                        Editar Cuenta
                    </NavLink>
                    <NavLink activeClassName="active" exact to={`/mi-cuenta/quiz`}>
                        Mi Beauty Quiz
                    </NavLink>
                    {/* <NavLink activeClassName="active" activeClassName="active" to={`/mi-cuenta/envios`}>
                        Mis Envíos
                    </NavLink>
                    <NavLink activeClassName="active" activeClassName="active" to={`/mi-cuenta/direcciones`}>
                        Mis Direcciones
                    </NavLink> */}

                    <h4 className="mt-4">Seguridad / Otros</h4>
                    <NavLink activeClassName="active" to={`/mi-cuenta/cambiar-contrasena`}>
                        Cambiar Contraseña
                    </NavLink>
                    <NavLink activeClassName="active" to={`/mi-cuenta/reclamos`}>
                        Reclamos
                    </NavLink>
                    <hr />
                    <div className="logout" onClick={handleLogout}>
                        Cerrar sesión
                    </div>
                </Col>
            </Row>
            <Modal isOpen={modal}>
                <ModalBody>
                    <div className="card-body text-center">
                        <center>
                            <div className="mt-6 mb-6">
                                <Spinner size={50} />
                                <h3 className="mt-6">Estamos cerrando la sesión...</h3>
                                <p>
                                    Esperamos volverte a ver pronto <span role="image">🤗</span>
                                </p>
                            </div>
                        </center>
                    </div>
                </ModalBody>
            </Modal>
        </Container>
    )
}
const Container = styled.div`
    .hot {
        padding: 1px 3px !important;
    }
    h4 {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 12px;
        margin-top: 5px;
    }
    a,
    .logout {
        display: block;
        box-sizing: border-box;
        color: #747678;
        text-decoration: none;
        display: flex;
        align-items: center;
        border-radius: 4px;
        white-space: normal;
        flex-shrink: 0;
        padding: 8px 16px;
        margin-bottom: 2px;
        min-height: 36px;
        transition: all 0.1s;
        cursor: pointer;
        :hover {
            background-color: rgba(38, 41, 44, 0.08);
            color: #26292c;
            text-decoration: none;
        }
    }
    a.active {
        font-weight: 600;
        background-color: rgba(221, 99, 126, 0.24);
        color: #dd637e;
    }
`
export default withRouter(withContext(Menu))
