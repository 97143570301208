import React from 'react'
import styled, { css } from 'styled-components'

function ColorPicker(props) {
    const { lang } = props
    return (
        <Container>
            <ul>
                {props.dataMap &&
                    props.dataMap.map(
                        (e, key) =>
                            e.key === props.id && (
                                <li key={key}>
                                    <Swatch
                                        className={props.data[props.id] === e.id && 'active'}
                                        onClick={() => props.handleChange(props.id, e)}
                                        active={props.data[props.id] === e.id}>
                                        <SwatchCircle color={e.color}></SwatchCircle>
                                        <span>{lang[e.name]}</span>
                                        <div className="checkFA">
                                            <i className="fas fa-check-circle"></i>
                                        </div>
                                    </Swatch>
                                </li>
                            )
                    )}
            </ul>
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    justify-content: center;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            display: inline-block;
            padding-right: 1rem;
        }
    }
`
const Swatch = styled.div`
    text-align: center;
    position: relative;
    span {
        font-size: 14px;
    }
    :hover {
        cursor: pointer;
        opacity: 0.8;
    }
    ${(props) =>
        props.active &&
        css`
            padding: 5px;
            border: 1px solid #dd637e;
            border-radius: 5px;
        `}
`
const SwatchCircle = styled.div`
    background: ${(props) => props.color};
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: 10px 20px;
`
export default ColorPicker
