import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@blueprintjs/core'

function LoaderAbsolute(props) {
    return (
        <Container {...props}>
            <Spinner size={60} />
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export default LoaderAbsolute
