import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Land from './pages/index'
import Quiz from './pages/quiz'
import EditQuiz from './pages/mi-cuenta/edit_quiz'
import MiCuenta from './pages/mi-cuenta'
import Login from './pages/login'
import OlvideContrasena from './pages/olvide-contrasena'
import { withContext } from './context/withContext'
import AppLoader from './components/AppLoader'
import ViewQuiz from './pages/mi-cuenta/view_quiz'
import Shipments from './pages/mi-cuenta/shipments'
import Addresses from './pages/mi-cuenta/address'
import ChangePassword from './pages/mi-cuenta/change_password'
import Claims from './pages/mi-cuenta/claims'

function Content(props) {
    const { loadingApp, isLogged } = props.context
    return (
        <React.Fragment>
            <Switch>
                <Route path="/" exact component={Land} />
                <Route path="/quiz" exact component={Quiz} />
                <Route path="/iniciar-sesion" exact component={Login} />
                <Route path="/olvide-contrasena" exact component={OlvideContrasena} />
                {loadingApp ? (
                    <AppLoader />
                ) : isLogged ? (
                    <React.Fragment>
                        <Route path="/mi-cuenta/quiz/edit/:id" exact component={EditQuiz} />
                        <Route path="/mi-cuenta" exact component={MiCuenta} />
                        <Route path="/mi-cuenta/quiz" exact component={ViewQuiz} />
                        <Route path="/mi-cuenta/envios" exact component={Shipments} />
                        <Route path="/mi-cuenta/direcciones" exact component={Addresses} />
                        <Route path="/mi-cuenta/cambiar-contrasena" exact component={ChangePassword} />
                        <Route path="/mi-cuenta/reclamos" exact component={Claims} />
                    </React.Fragment>
                ) : (
                    <Redirect to="/iniciar-sesion" />
                )}
            </Switch>
        </React.Fragment>
    )
}
export default withContext(Content)
