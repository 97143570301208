import React from 'react'
import styled from 'styled-components'

function Brands(props) {
    let {data, dataMap, id, lang} = props;
    if (id === "stores") {
        dataMap = dataMap.filter(d => d.description === lang.language_code | "es")
    }
    return (
        <Container>
            {data &&
                dataMap.map((e, key) => (
                    <Brand
                        key={key}
                        className={props.value && props.value.includes(e.id) && `active`}
                        onClick={() => props.handleChange(props.id, e)}>
                        <img src={e.photo} alt="Image" />
                        {props.showName && <Name>{e.name}</Name>}
                        <div className="checkFA">
                            <i className="fas fa-check-circle"></i>
                        </div>
                    </Brand>
                ))}
        </Container>
    )
}
const Container = styled.div`
    display: block;
    .active {
        border-color: #dd637e !important;
    }
`
const Name = styled.div`
    position: absolute;
    bottom: 0;
    background: white;
    width: 100%;
    font-size: 16px;
    color: rgb(221, 99, 126);
    text-align: center;
    padding: 3px 0;
    border-top: 1px solid #eee;
`
const Brand = styled.div`
    position: relative;
    display: inline-block;
    border: 1px solid #eee;
    border-radius: 2px;
    width: 49.35%;
    margin: 0;
    border: 1px solid #ebeced;
    overflow: hidden;
    min-height: 90px;
    padding: 30px 0;
    img {
        object-fit: contain;
        width: 100%;
        min-height: 90px;
    }
    @media only screen and (min-width: 768px) {
        width: calc(25% - 3px);
        padding: 35px 0;
    }
    @media only screen and (min-width: 1024px) {
        width: calc(16.7% - 3px);
        padding: 20px 0;
    }
    @media only screen and (min-width: 1280px) {
        width: 12.33%;
    }
`
export default Brands
