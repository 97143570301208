import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { Header, Footer } from '../layouts'
import { Button } from '../components/elements/Button'
import { Mixpanel } from '../components/Mixpanel'
import {withContext} from "../context/withContext";

function Index(props) {
    const { context } = props
    const lang = require(`../config/i18n/${context.language}.json`)

    return (
        <Container className="wrapper">
            <Header />
            <div className="container">
                <div id="title" className="mt-6 mb-6">
                    <h1>{lang.choose_your_subscription}</h1>
                    <p>{lang.cancel_anytime}</p>
                </div>
                <Row>
                    <Col xs={12} md={4}>
                        <Plan plan1>
                            <div>
                                <Link to={`/quiz?plan=6`}>
                                    <img
                                        alt={"rose gold.png"}
                                        src={lang.language_code == "es" ? "https://images.squarespace-cdn.com/content/v1/5e66c4f616532e730099986e/1633396453872-1PFZ600LDBY4IAAML83N/rose+gold.png" : "https://images.squarespace-cdn.com/content/v1/5e66c4f616532e730099986e/1620923916412-EO5DT38EOROUZXNNR0D9/fc52df44-5209-4e4d-bfb0-d8513255cb9a.jpg"}
                                        style={{ width: "325px", cursor: "pointer" }}
                                        onClick={() => Mixpanel.track('Seleccionó Plan', { Plan: '4', plan_name: 'Rose' })}
                                    />
                                </Link>
                            </div>
                        </Plan>
                    </Col>
                    <Col xs={12} md={4}>
                        <Plan plan2>
                            <div>
                                <Link to={`/quiz?plan=5`}>
                                    <img
                                        alt={"fuscia.png"}
                                        src={lang.language_code == "es" ? "https://images.squarespace-cdn.com/content/v1/5e66c4f616532e730099986e/1633534172947-JIYVS9CNC32FQ28J2TL7/fuscia.png" : "https://images.squarespace-cdn.com/content/v1/5e66c4f616532e730099986e/1620923924514-UJLE4Q433SJ25DAKTQUX/acd465d6-f7e8-4fb1-9e1b-4d0dcaf6d983.jpg"}
                                        style={{ width: "325px", cursor: "pointer" }}
                                        onClick={() =>
                                            Mixpanel.track('Seleccionó Plan', { Plan: '5', plan_name: 'Fucsia' })
                                        }
                                    />
                                </Link>
                            </div>
                        </Plan>
                    </Col>
                    <Col xs={12} md={4}>
                        <Plan plan3>
                            <div>
                                <Link to={`/quiz?plan=4`}>
                                    <img
                                        alt={"rose.png"}
                                        src={lang.language_code == "es" ? "https://images.squarespace-cdn.com/content/v1/5e66c4f616532e730099986e/1633396488069-XPBRIX5ZV7R5EKWWCX2A/rose.png" : "https://images.squarespace-cdn.com/content/v1/5e66c4f616532e730099986e/1620923946130-6X1612HRKHHGG1ON9NK9/825bdca6-d37a-4d0d-92ca-23a864bdaaab.jpg"}
                                        style={{ width: "325px", cursor: "pointer" }}
                                        onClick={() => Mixpanel.track('Seleccionó Plan', { Plan: '4', plan_name: 'Rose' })}
                                    />
                                </Link>
                            </div>
                        </Plan>
                    </Col>
                </Row>
            </div>
            <Footer />
        </Container>
    )
}
const Container = styled.div`
    #title {
        text-align: center;
        h1 {
            font-weight: 900;
            font-size: 2.2rem;
        }
        p {
            padding: 0;
            margin: 0;
            font-size: 0.9rem;
        }
    }
`
const Plan = styled.div`
    border-radius: 10px;
    padding: 10px !important;
    margin: 10px;
    text-align: center;
    width: 100%;
    color: #fff;
    h4 {
        text-transform: uppercase;
    }
    ${(props) =>
        props.plan1 &&
        css`
            background: linear-gradient(to top, #f9d7ce 0%, #e5b6b0 100%);
        `}
    ${(props) =>
        props.plan2 &&
        css`
            background: linear-gradient(to top, #f79cbf 0%, #d87098 100%);
        `}
    ${(props) =>
        props.plan3 &&
        css`
            background: linear-gradient(to top, #ffdde2 0%, #ffbccf 100%);
        `}
`
export default withContext(Index)
