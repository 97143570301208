import React from 'react'
import styled, { css } from 'styled-components'
import ReactTooltip from 'react-tooltip'

export const Button = (props) => {
    return props.disabled ? (
        <>
            <FakeButton primary data-tip={props.disableReason}>
                {props.children}
            </FakeButton>
            <ReactTooltip />
        </>
    ) : (
        <Container {...props}>{props.children}</Container>
    )
}
const Container = styled.button`
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    white-space: nowrap;
    line-height: 1;
    font-size: 1.125rem;
    padding: 12px 70px;
    :hover {
        opacity: 0.85;
    }
    ${(props) =>
        props.block &&
        css`
            width: 100% !important;
        `}
    ${(props) =>
        props.primary &&
        css`
            border: 1px solid #dd637e;
            background-color: #dd637e;
            color: #fff;
        `}
    ${(props) =>
        props.secondary &&
        css`
            border: 1px solid #2a2527;
            background-color: #2a2527;
            color: #fde7e9 !important;
        `}
    ${(props) =>
        props.ghost &&
        css`
            border: 1px solid #dd637e;
            background-color: transparent;
            color: #dd637e !important;
            font-weight: 400;
        `}
    ${(props) =>
        props.dark &&
        css`
            border: 1px solid #000;
            background-color: #000;
            color: #fff !important;
            font-weight: 400;
        `}
`
const FakeButton = styled.button`
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    white-space: nowrap;
    line-height: 1;
    font-size: 1.125rem;
    padding: 12px 70px;
    opacity: 0.6;
    cursor: not-allowed;
    ${(props) =>
        props.block &&
        css`
            width: 100%;
        `}
    ${(props) =>
        props.primary &&
        css`
            border: 1px solid #dd637e;
            background-color: #dd637e;
            color: #fff;
        `}
    ${(props) =>
        props.secondary &&
        css`
            border: 1px solid #2a2527;
            background-color: #2a2527;
            color: #fde7e9 !important;
        `}
    ${(props) =>
        props.ghost &&
        css`
            border: 1px solid #dd637e;
            background-color: transparent;
            color: #dd637e !important;
            font-weight: 400;
        `}
`
