import axios from 'axios'

const baseURL =
    process.env.NODE_ENV === 'development'
        ? 'https://api.lacajarosa.com/api/v1'
        : 'https://api.lacajarosa.com/api/v1'

export const stripeKEY =
    process.env.NODE_ENV === 'development'
        ? 'pk_test_nPCAynnFonMZjM9nTaGJ6w0H00MCYCa9CA'
        : 'pk_live_YAHbgMu2YgsoH9MwIjwh91h8008ZXjzjxU'

export const API = axios.create({
    baseURL: baseURL,
    headers: {
        domain: process.browser ? window.location.host.replace('www.', '') : '',
    },
})
