import React from 'react'
import styled from 'styled-components'

function Questions(props) {
    const { lang } = props
    return (
        <Container>
            <div className="answer-radio single" id="quiz-answer-grid">
                {props.dataMap &&
                    props.dataMap.map(
                        (e, key) =>
                            e.key === props.id && (
                                <div
                                    className="choice pointer"
                                    role="button"
                                    tabindex="0"
                                    aria-pressed="false"
                                    key={key}
                                    onClick={() => props.handleChange(props.id, e)}>
                                    <div className="media">
                                        <div className="answer-container mr-3">
                                            <div
                                                className={`radio-button left ${
                                                    e.id === props.data[props.id] && 'checked'
                                                }`}>
                                                <div className="control">
                                                    <div className="check"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <div>
                                                <div className="h6">{lang[e.name]}</div>
                                                <p>{lang[e.description]}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    )}
            </div>
        </Container>
    )
}
const Container = styled.div`
    @media only screen and (min-width: 768px) {
        #quiz-answer-grid {
            width: 60%;
            margin: 0 auto;
        }
    }
    @media only screen and (min-width: 1024px) {
        #quiz-answer-grid {
            width: 45%;
        }
    }
    .media {
        padding-top: 15px;
        border-bottom: 1px solid #eee;
    }
`
export default Questions
