import React from 'react'
import styled from 'styled-components'
import FeatherIcons from 'feather-icons-react'
import { Link } from 'react-router-dom'
import { withContext } from '../../context/withContext'
import * as DOFlag from '../../static/images/do_flag.png'
import * as USFlag from '../../static/images/us_flag.png'

function Desktop(props) {
    const { context } = props
    const lang = require(`../../config/i18n/${context.language}.json`)
    const user = props.context.user
    return (
        <Container>
            <Content>
                <div id="logo">
                    <a href="https://lacajarosa.com/edicion-del-mes#planes-edicion">
                        <img src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2020/3/UzeoAFYCwT-Logo-footer.png" />
                    </a>
                </div>
                <div id="flags">
                    {context.language === "en" ? (
                        <div className={"pointer"} onClick={() => {
                            props.changeLanguage('es', context)
                        }}>
                            <img src={DOFlag} /> ¿Español?
                        </div>
                    ) : (
                        <div className={"pointer"} onClick={() => {
                            props.changeLanguage('en', context)
                        }}>
                            <img src={USFlag} /> English?
                        </div>
                    )}
                </div>
                <div id="secure">
                    {context.isLogged ? (
                        <div className="pointer">
                            <Link to={'/mi-cuenta'}>
                                <FeatherIcons icon="lock" size={16} /> {lang.hello} {user.first_name}!
                            </Link>
                        </div>
                    ) : (
                        <div className="pointer">
                            <Link to={'/iniciar-sesion'}>
                                <FeatherIcons icon="lock" size={15} /> {lang.sign_in}
                            </Link>
                        </div>
                    )}
                </div>
            </Content>
        </Container>
    )
}
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    a {
        color: #000;
        :hover {
            text-decoration: none;
            opacity: 0.7;
        }
    }
`
const Container = styled.div`
    border-top: 4px solid #dd637e;
    position: relative !important;
    background-color: snow !important;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #eee;
    #logo {
        img {
            height: 70px;
        }
    }
    #secure {
        font-weight: 600;
    }
    
    #flags {
        font-weight: 500;
        img {
            width: 20px;
            heigth: 20px;
        }
    }
`
export default withContext(Desktop)
