import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { Input } from '../components/FormGroups'
import { Button } from '../components/elements/Button'
import { withContext } from '../context/withContext'

function Footer(props) {
    const { context } = props
    const lang = require(`../config/i18n/${context.language}.json`)

    return (
        <Container className="mt-7">
            <FooterImgContainer>
                <div className="container-fluid">
                    <Row>
                        <Col xs={12} md={4}>
                            <CenterContainer>
                                <img src={lang.language_code === "en" ? "/img/p-2-01.png" : "/img/p-1-04.png"} style={{width: "428px"}} />
                            </CenterContainer>
                        </Col>
                        <Col xs={12} md={4}>
                            <CenterContainer>
                                <img src={lang.language_code === "en" ? "/img/p-2-02.png" : "/img/p-1-05.png"} style={{width: "428px"}} />
                            </CenterContainer>
                        </Col>
                        <Col xs={12} md={4}>
                            <CenterContainer>
                                <img src={lang.language_code === "en" ? "/img/p-2-03.png" : "/img/p-1-06.png"} style={{width: "428px"}} />
                            </CenterContainer>
                        </Col>
                    </Row>
                </div>
            </FooterImgContainer>
            <MasterFooterContainer>
                <div className="container-fluid">
                    <Row>
                        <Col xs={12} md={4}>
                            <img src="https://images.squarespace-cdn.com/content/v1/5e66c4f616532e730099986e/1584558148305-BS7LGSVBM9W3O8FH0RLO/ke17ZwdGBToddI8pDm48kILRJCuaKCsR7oW7rcRu_1flfiSMXz2YNBs8ylwAJx2qrCLSIWAQvdC7iWmC9HNtRa3YpHllbNiL30aCfXKBu55GLI1wwvepMPqZW7rz76YgOGd8HZvxP0WH9shuO32GDg/la-caja-rosa.png?format=500w" />
                            <p style={{ maxWidth: '400px', paddingTop: '10px', opacity: 0.8, fontSize: '14px' }}>
                                {lang.our_mission_footer}
                            </p>
                        </Col>
                        <Col xs={12} md={3}>
                            <ul>
                                <li>
                                    <a href="https://www.lacajarosa.com/tienda">{lang.store}</a>
                                </li>
                                <li>
                                    <a href="https://www.lacajarosa.com/blog">{lang.blog}</a>
                                </li>
                                <li>
                                    <a href="https://www.lacajarosa.com/edicion-del-mes">{lang.box_of_the_month}</a>
                                </li>
                                <li>
                                    <a href="https://www.lacajarosa.com/terminos-y-condiciones">
                                        {lang.terms_conditions}
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.lacajarosa.com/preguntas-frecuentes">{lang.frequent_questions}</a>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={12} md={5}>
                            {/* <h2>
                                Recibe la <strong className="pink">#CartaRosa</strong>
                            </h2>
                            <p style={{ paddingTop: '10px', opacity: 0.8, fontSize: '14px' }}>
                                Tips de belleza, bienestar y todo lo relacionado a ser mujer en tu correo dos veces al
                                mes.
                            </p>
                            <Input style={{ width: '308px' }} className="d-inline" />
                            <Button className="d-inline" dark>
                                Recibe las Cartas
                            </Button> */}
                        </Col>
                    </Row>
                </div>
            </MasterFooterContainer>
        </Container>
    )
}
const Container = styled.div`
    text-align: center;
    background: snow;
    margin-top: 8rem;
    font-size: 14px;
`
const FooterImgContainer = styled.div`
    background: #f9bcbe;
    padding-top: 20px;
    padding-bottom: 20px;
`
const CenterContainer = styled.div`
    text-transform: uppercase;
    display: text;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 600px) {
        img {
            width: 100%;
        }
    }
`
const MasterFooterContainer = styled.div`
    padding: 25px;
    text-align: left;
    padding-top: 45px;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            a {
                color: #000;
                padding-bottom: 0.05em;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-bottom-color: rgba(0, 0, 0, 0.3);
                :hover {
                    opacity: 0.7;
                    text-decoration: none;
                }
            }
            padding-bottom: 1rem;
            font-weight: 600;
        }
    }
`
export default withContext(Footer)
