import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'

function BigChoices(props) {
    const { lang } = props
    return (
        <Container>
            <Row>
                {props.dataMap &&
                    props.dataMap.length > 0 &&
                    props.dataMap.map(
                        (e, key) =>
                            e.key === props.id && (
                                <Col xs={12} md={3} key={key} className="mb-3">
                                    <Card
                                        key={key}
                                        className={
                                            props.single
                                                ? props.value === e.id && `active`
                                                : props.value && props.value.includes(e.id) && `active`
                                        }
                                        onClick={() => props.handleChange(props.id, e)}>
                                        <img src={e.image} alt={e.name} />
                                        <Name>{lang[e.name]}</Name>
                                        <div className="checkFA">
                                            <i className="fas fa-check-circle"></i>
                                        </div>
                                    </Card>
                                </Col>
                            )
                    )}
            </Row>
        </Container>
    )
}
const Container = styled.div`
    .active {
        border-color: #dd637e !important;
        i {
            color: #fff;
            background: #dd637e;
            border-radius: 50%;
            height: 35px;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 10px;
            margin-top: 7px;
        }
    }
`
const Card = styled.div`
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
    object-fit: cover;
`
const Name = styled.div`
    position: absolute;
    bottom: 0;
    background: white;
    width: 100%;
    font-size: 20px;
    color: rgb(221, 99, 126);
    text-align: center;
    padding: 3px 0;
`
export default BigChoices
