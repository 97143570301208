import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FeatherIcons from 'feather-icons-react'
import { Link } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { Intent } from '@blueprintjs/core'
// local
import { withContext } from '../context/withContext'
import { Header, Footer } from '../layouts'
import { Input } from '../components/FormGroups'
import { Button } from '../components/elements/Button'
import { API } from '../utils/config'
import { OurToaster } from '../components/OurToaster'
import { Alert } from 'reactstrap'
import { Mixpanel } from '../components/Mixpanel'

function OlvideContrasena(props) {
    const [username, setUsername] = useState('')
    const [loading, showLoading] = useState(false)
    const [alert, showAlert] = useState(false)
    const [completed, setCompleted] = useState(false)

    useEffect(() => {
        Mixpanel.track('View: Forgot Password')
        if (props.context.isLogged) {
            props.history.push('/mi-cuenta')
        }
    }, [props.context.isLogged])

    const handleLogin = (e) => {
        e.preventDefault()
        showLoading(true)
        showAlert(false)
        API.post('/recovery/', { email: username })
            .then((response) => {
                Mixpanel.track('Password Reset Requested')
                setCompleted(true)
            })
            .catch((err) => {
                Mixpanel.track('Error at Password Reset')
                OurToaster.show({ message: 'Verifica los datos e intenta de nuevo.', intent: Intent.DANGER })
                showLoading(false)
                showAlert(true)
            })
    }
    return (
        <div>
            <Header />
            {completed ? (
                <Container>
                    <center className="mb-6 mt-8">
                        <span style={{ color: 'green' }}>
                            <FeatherIcons size={45} icon="check" className="mb-3" />
                        </span>
                        <h3>¡Tu contraseña va en camino a tu correo!</h3>
                        <p>
                            Te acabamos de enviar una contraseña provisional al correo que indicaste, con esa contraseña
                            puedes acceder a tu cuenta y cambiarla por una que recuerdes.
                        </p>
                    </center>

                    <center className="mt-4">
                        <Link to={'/iniciar-sesion'}>Volver al Inicio de Sesión</Link>
                    </center>
                </Container>
            ) : (
                <Container>
                    <center className="mb-6 mt-8">
                        <FeatherIcons size={45} icon="help-circle" className="mb-3" />
                        <h3>Olvidé mi Contraseña</h3>
                        <p>
                            Indica debajo cual es tu correo electrónico para que te podamos enviar una contraseña nueva.
                        </p>
                    </center>
                    <form onSubmit={handleLogin}>
                        <Input
                            required
                            id="email"
                            type="email"
                            value={username}
                            label="Correo electrónico"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        {alert && (
                            <Alert color="warning">
                                Este correo no existe en nuestro sistema, ¿Estás segura que es ese?
                            </Alert>
                        )}
                        <Button primary className="w-100" disabled={loading} block>
                            {loading ? (
                                <Loader type="ThreeDots" color="#FFF" height={10} width={100} />
                            ) : (
                                'Reiniciar Contraseña'
                            )}
                        </Button>
                    </form>
                    <center className="mt-4">
                        <Link to={'/iniciar-sesion'}>&larr; Iniciar Sesión</Link>
                    </center>
                </Container>
            )}
            <Footer />
        </div>
    )
}
const Container = styled.div`
    max-width: 550px;
    margin: 0 auto;
    button {
        width: 100% !important;
    }
`
export default withContext(OlvideContrasena)
