import { API } from '../utils/config'

export function ListPlans() {
    return API(`/subscriptions/plans/`)
}
export function ViewPlan(plan) {
    return API(`/subscriptions/plans/view/${plan}/`)
}

export function NewSubscription(data) {
    return API.post(`/subscriptions/new/`, data)
}
