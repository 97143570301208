import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GetQuiz } from '../../api/quizes'

function ViewQuiz(props) {
    const [data, setData] = useState({
        skin_tone: {},
        skin_type: {},
        eyes_color: {},
        hair_color: {},
        hair_texture: {},
        // hair_handle: {},
        enamel_tone: {},
        makeup_use: {},
        skin_routine: {},
        stores: [],
        beauty_brands: [],
        // prefered_makeup: [],
        // personal_care: [],
        // care_products: [],
        products_type: {},
    })

    useEffect(() => {
        GetQuiz(props.token, props.quiz).then((response) => {
            setData(response.data)
        })
    }, [])
    return (
        <Container>
            <div className="card">
                <TableContainer>
                    <Result className="py-3" style={{ background: '#eeeeee70' }}>
                        <div id="question">
                            <p className="question_p">
                                <strong>PREGUNTA</strong>
                            </p>
                        </div>
                        <div id="answer">
                            <strong>RESPUESTA</strong>
                        </div>
                    </Result>

                    <Result className="py-3" id="skin_tone">
                        <div id="question">
                            <p className="question_p">
                                <strong>¿Cuál es el color de tu piel?</strong>
                            </p>
                        </div>
                        <div id="answer">
                            {data.skin_tone ? (
                                <>
                                    <Swatch background={data.skin_tone.color} /> {data.skin_tone.name}
                                </>
                            ) : (
                                '-'
                            )}
                        </div>
                    </Result>

                    <Result className="py-3" id="skin_type">
                        <div id="question">
                            <p className="question_p">
                                <strong>¿Cuál es tu tipo de piel?</strong>
                            </p>
                        </div>
                        <div id="answer">{data.skin_type ? data.skin_type.name : '-'}</div>
                    </Result>

                    <Result className="py-3" id="eyes_color">
                        <div id="question">
                            <p className="question_p">
                                <strong>¿Cuál es el color de tus ojos?</strong>
                            </p>
                        </div>
                        <div id="answer">
                            {data.eyes_color ? (
                                <>
                                    <Swatch background={data.eyes_color.color} /> {data.eyes_color.name}
                                </>
                            ) : (
                                '-'
                            )}
                        </div>
                    </Result>

                    <Result className="py-3" id="hair_color">
                        <div id="question">
                            <p className="question_p">
                                <strong>¿Cuál es el color de tu cabello?</strong>
                            </p>
                        </div>
                        <div id="answer">
                            {data.hair_color ? (
                                <>
                                    <Swatch background={data.hair_color.color} /> {data.hair_color.name}
                                </>
                            ) : (
                                '-'
                            )}
                        </div>
                    </Result>

                    <Result className="py-3" id="hair_texture">
                        <div id="question">
                            <p className="question_p">
                                <strong>¿Cuál es la textura de tu cabello?</strong>
                            </p>
                        </div>
                        <div id="answer">{data.hair_texture ? data.hair_texture.name : '-'}</div>
                    </Result>

                    {/*<Result className="py-3" id="hair_handle">*/}
                    {/*    <div id="question">*/}
                    {/*        <p className="question_p">*/}
                    {/*            <strong>¿Cómo manejas tu cabello?</strong>*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*    <div id="answer">{data.hair_handle ? data.hair_handle.name : '-'}</div>*/}
                    {/*</Result>*/}

                    <Result className="py-3" id="enamel_tone">
                        <div id="question">
                            <p className="question_p">
                                <strong>¿Qué tonos de esmaltes prefieres?</strong>
                            </p>
                        </div>
                        <div id="answer">{data.enamel_tone ? data.enamel_tone.name : '-'}</div>
                    </Result>

                    <Result className="py-3" id="makeup_use">
                        <div id="question">
                            <p className="question_p">
                                <strong>¿Qué tanto maquillaje usas?</strong>
                            </p>
                        </div>
                        <div id="answer">{data.makeup_use ? data.makeup_use.name : '-'}</div>
                    </Result>

                    <Result className="py-3" id="skin_routine">
                        <div id="question">
                            <p className="question_p">
                                <strong>¿Cuál es tu rutina de la piel?</strong>
                            </p>
                        </div>
                        <div id="answer">{data.skin_routine ? data.skin_routine.name : '-'}</div>
                    </Result>

                    <Result className="py-3" id="stores">
                        <div id="question">
                            <p className="question_p">
                                <strong>¿Dónde sueles comprar productos de belleza?</strong>
                            </p>
                        </div>
                        <div id="answer">
                            {data.stores ? (
                                <ul>
                                    {data.stores.map((e, key) => (
                                        <li key={key}>
                                            <i className="fas fa-check"></i> {e.name}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                '-'
                            )}
                        </div>
                    </Result>

                    <Result className="py-3" id="beauty_brands">
                        <div id="question">
                            <p className="question_p">
                                <strong>¿Qué marcas quisieras probar?</strong>
                            </p>
                        </div>
                        <div id="answer">
                            {data.beauty_brands ? (
                                <ul>
                                    {data.beauty_brands.map((e, key) => (
                                        <li key={key}>
                                            <i className="fas fa-check"></i> {e.name}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                '-'
                            )}
                        </div>
                    </Result>

                    {/*<Result className="py-3" id="prefered_makeup">*/}
                    {/*    <div id="question">*/}
                    {/*        <p className="question_p">*/}
                    {/*            <strong>¿Cuáles productos de maquillaje quieres recibir?</strong>*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*    <div id="answer">*/}
                    {/*        {data.prefered_makeup ? (*/}
                    {/*            <ul>*/}
                    {/*                {data.prefered_makeup.map((e, key) => (*/}
                    {/*                    <li key={key}>*/}
                    {/*                        <i className="fas fa-check"></i> {e.name}*/}
                    {/*                    </li>*/}
                    {/*                ))}*/}
                    {/*            </ul>*/}
                    {/*        ) : (*/}
                    {/*            '-'*/}
                    {/*        )}*/}
                    {/*    </div>*/}
                    {/*</Result>*/}

                    {/*<Result className="py-3" id="personal_care">*/}
                    {/*    <div id="question">*/}
                    {/*        <p className="question_p">*/}
                    {/*            <strong>¿Cuáles productos de cuidado personal quieres recibir?</strong>*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*    <div id="answer">*/}
                    {/*        {data.personal_care ? (*/}
                    {/*            <ul>*/}
                    {/*                {data.personal_care.map((e, key) => (*/}
                    {/*                    <li key={key}>*/}
                    {/*                        <i className="fas fa-check"></i> {e.name}*/}
                    {/*                    </li>*/}
                    {/*                ))}*/}
                    {/*            </ul>*/}
                    {/*        ) : (*/}
                    {/*            '-'*/}
                    {/*        )}*/}
                    {/*    </div>*/}
                    {/*</Result>*/}

                    {/*<Result className="py-3" id="care_products">*/}
                    {/*    <div id="question">*/}
                    {/*        <p className="question_p">*/}
                    {/*            <strong>¿Cuáles productos de cuidado quieres recibir?</strong>*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*    <div id="answer">*/}
                    {/*        {data.care_products ? (*/}
                    {/*            <ul>*/}
                    {/*                {data.care_products.map((e, key) => (*/}
                    {/*                    <li key={key}>*/}
                    {/*                        <i className="fas fa-check"></i> {e.name}*/}
                    {/*                    </li>*/}
                    {/*                ))}*/}
                    {/*            </ul>*/}
                    {/*        ) : (*/}
                    {/*            '-'*/}
                    {/*        )}*/}
                    {/*    </div>*/}
                    {/*</Result>*/}


                    <Result className="py-3" id="product_type">
                        <div id="question">
                            <p className="question_p">
                                <strong>¿Qué tipo de productos te gustaría recibir?</strong>
                            </p>
                        </div>
                        <div id="answer">{data.products_type ? data.products_type.name : '-'}</div>
                    </Result>
                </TableContainer>
            </div>
        </Container>
    )
}

const Container = styled.div``
const TableContainer = styled.div``
const Result = styled.div`
    border-bottom: 1px solid #eee;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    p {
        margin: 0;
        padding: 0;
    }
    #question {
        flex: 1;
    }
    #answer {
        display: flex;
        align-items: center;
        flex: 1;
    }
    .question_p {
        padding-right: 5rem;
    }
    :hover {
        background-color: #eeeeee70;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        i {
            margin-right: 5px;
            color: #dd637e !important;
        }
    }
`
const Swatch = styled.div`
    display: inline-block;
    height: 25px;
    width: 25px;
    background: ${(props) => props.background};
    margin-right: 5px;
    border-radius: 50%;
`
export default ViewQuiz
