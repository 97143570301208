import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Redirect, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { Spinner, Intent } from '@blueprintjs/core'
import { Modal, ModalBody } from 'reactstrap'
// local import
import { Header, Footer } from '../../layouts'
import { Button } from '../../components/elements/Button'
import StepsCircle from '../../components/elements/StepsCircles'
import Questions from '../../components/quiz_steps/questions'
import { GetOptions, EditViewQuiz } from '../../api/quizes'
import { GetBrands, GetStores } from '../../api/products'
import { withContext } from '../../context/withContext'
import { OurToaster } from '../../components/OurToaster'

function Quiz(props) {
    const [data, setData] = useState({})
    // arrays
    const [brands, setBrands] = useState([])
    const [stores, setStores] = useState([])
    const [options, setOptions] = useState([])
    // bools and ints
    const [steps, setStep] = useState(1)
    const [loading, setLoader] = useState(false)
    const [modal, toggle] = useState(false)
    const [activeKey, setActiveKey] = useState('skin_tone')
    const id = props.match.params.id
    const { language, token } = props.context
    const lang = require(`../../config/i18n/${language}.json`)

    useEffect(() => {
        console.log(id, token)
        RetrieveOptions()
        EditViewQuiz(id, token).then((response) => {
            console.log(response.data)
            setData(response.data)
        })
    }, [])

    const RetrieveOptions = async () => {
        try {
            let r1 = await GetOptions()
            let r2 = await GetStores()
            let r3 = await GetBrands()
            setOptions(r1.data)
            setStores(r2.data)
            setBrands(r3.data)
        } catch (error) {
            console.log(error)
        }
    }

    const changeSwatch = (akey, e) => {
        let newData = Object.assign({}, data)
        newData[akey] = e.id
        setData(newData, )
        console.log(steps);
        setStep(steps + 1)
        window.scrollTo(0, 0)
        //CheckStepPropgress()
    }

    const handleNextButton = (akey, step) => {
        let more = 0
        if (steps === 5) more = 1
        if (steps === 11) more = 3
        window.scrollTo(0, 0)
        if (!data[akey]) {
            alert('Favor seleccionar almenos 1 opción')
        } else {
            setStep(steps + 1)
        }
        setStep(steps + 1 + more)
        // if (steps + 1 === 13) {
        //     setTimeout(() => {
        //         setLoader(false)
        //     }, 2500)
        // }
        //CheckStepPropgress()
    }

    const handleBackButton = () => {
        let more = 0
        if (steps === 7) more = 1
        if (steps === 15) more = 3
        window.scrollTo(0, 0)
        setStep(steps - 1 - more)
    }

    const MultipleSelect = (akey, e) => {
        let newData = Object.assign({}, data)
        if (newData[akey]) {
            if (newData[akey].includes(e.id)) {
                let index = newData[akey].indexOf(e.id)
                newData[akey].splice(index, 1)
            } else {
                newData[akey].push(e.id)
            }
        } else {
            newData[akey] = []
            newData[akey].push(e.id)
        }
        setData(newData)
        //CheckStepPropgress()
    }

    const handleCheckbox = (index, e) => {
        let value = e.target.value
        if (data[index] && data[index] === value) {
            data[index] = null
        } else {
            data[index] = value
        }
        setData(data)
    }

    const handleChange = (e) => {
        let newData = Object.assign({}, data)
        let index = e.target.name
        let value = e.target.value
        newData[index] = value
        setData(newData)
    }

    const CheckStepPropgress = async () => {
        if (steps === 16) {
            setLoader(true)
            toggle(true)
            try {
                let res = await EditViewQuiz(id, token, data)
                console.log(res)
                setLoader(false)
            } catch (error) {
                console.log(error)
                toggle(false)
                setStep(14)
                OurToaster.show({ message: 'Al parecer hubo un error. Inténtalo de nuevo.', intent: Intent.DANGER })
            }
        }
    }

    useEffect(() => {
        CheckStepPropgress()
    }, [data, steps]);

    const RedirectToAccount = () => {
        props.history.push('/mi-cuenta')
    }
    return (
        <Container className="wrapper">
            <Header />
            {steps >= 1 && steps <= 15 && <StepsCircle active={steps} />}
            <div className="container">
                <Questions
                    options={options}
                    brands={brands}
                    stores={stores}
                    handleChange={handleChange}
                    MultipleSelect={MultipleSelect}
                    handleCheckbox={handleCheckbox}
                    changeSwatch={changeSwatch}
                    steps={steps}
                    data={data}
                    lang={lang}
                />
            </div>

            {steps <= 15 && (
                <ButtonContainer>
                    {steps > 1 && (
                        <Button ghost onClick={handleBackButton}>
                            Atrás
                        </Button>
                    )}
                    <Button primary onClick={() => handleNextButton(activeKey, steps)}>
                        {steps > 14 ? 'Guardar' : 'Siguiente'}
                    </Button>
                </ButtonContainer>
            )}
            <Modal isOpen={modal}>
                <ModalBody>
                    <div className="card-body text-center">
                        <center>
                            {loading ? (
                                <Spinner size={35} />
                            ) : (
                                <div className="mt-6 mb-6">
                                    <img
                                        src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2020/4/dsNRd8zaWT-undraw_makeup_artist_rxn8.svg"
                                        height="150px"
                                        className="mb-6"
                                    />
                                    <h3>¡Editado exitosamente!</h3>
                                    <p>
                                        Gracias por editar tu quiz, nuestras asesoras acaban de recibir una notificación
                                        y ya están al tanto de tus nuevos intereses <span role="image">✨</span>
                                    </p>
                                    <Button primary onClick={RedirectToAccount} block>
                                        Regresar a Mi Cuenta
                                    </Button>
                                </div>
                            )}
                        </center>
                    </div>
                </ModalBody>
            </Modal>
            <Footer />
        </Container>
    )
}
const Container = styled.div`
    .title-maxed {
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 2.5rem;
    }
    h3 {
        font-weight: 600;
    }
`
const ButtonContainer = styled.div`
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 1rem;
    button {
        display: inline-block;
        margin-right: 15px;
    }
    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: row;
        button {
            width: 50%;
            margin: 0;
            padding: 15px;
        }
    }
`
export default withRouter(withContext(Quiz))
