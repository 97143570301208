import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { Header, Footer } from '../../layouts'
import AccountMenu from '../../components/elements/AccountMenu'
import UnderMaintenance from '../../components/elements/UnderMaintenance'

function Shipments(props) {
    return (
        <Container>
            <Header />
            <div className="container">
                <Row>
                    <Col xs={12} className="mb-4">
                        <center className="mb-3">
                            <h3>Direcciones</h3>
                            <p>¿Algún cambio en tu dirección?, editala aquí.</p>
                        </center>
                        <hr />
                    </Col>
                    <Col xs={{ size: 12, order: 2 }} md={{ size: 3, order: 1 }}>
                        <AccountMenu />
                    </Col>
                    <Col xs={{ size: 12, order: 1 }} md={{ size: 9, order: 2 }}>
                        <UnderMaintenance />
                    </Col>
                </Row>
            </div>
            <Footer />
        </Container>
    )
}
const Container = styled.div``
export default Shipments
