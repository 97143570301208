import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Intent } from '@blueprintjs/core'
import { Row, Col } from 'reactstrap'
// local
import { Button } from '../../components/elements/Button'
import { Header, Footer } from '../../layouts'
import AccountMenu from '../../components/elements/AccountMenu'
import { withContext } from '../../context/withContext'
import { Input, Select } from '../../components/FormGroups'
import LoaderAbsolute from '../../components/elements/LoaderAbsolute'
import { ViewEditMember, GetProvinces } from '../../api/core'
import { OurToaster } from '../../components/OurToaster'

function MiCuenta(props) {
    const { user } = props.context
    const [provinces, setProvinces] = useState([])
    const [data, setData] = useState({})
    const [loading, setLoader] = useState({})
    const token = props.context.token
    const userData = props.context.user

    useEffect(() => {
        RetrieveData()
    }, [])

    const RetrieveData = async (e) => {
        setLoader(true)
        try {
            let res = await ViewEditMember(token, userData.uid)
            let r4 = await GetProvinces()
            setLoader(false)
            console.log(res.data)
            setData(res.data)
            setProvinces(r4.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (e) => {
        let newData = Object.assign({}, data)
        let index = e.target.name
        let value = e.target.value
        newData[index] = value
        console.log(newData)
        setData(newData)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)
        let token = props.context.token
        try {
            let res = await ViewEditMember(token, userData.uid, data)
            console.log(res.data)
            OurToaster.show({ message: 'Usuario actualizado', intent: Intent.SUCCESS, icon: 'tick' })
            setLoader(false)
        } catch (error) {
            OurToaster.show({
                message: 'Al parecer hubo un error, repórtalo a info@lacajarosa.com',
                intent: Intent.DANGER,
            })
        }
    }

    return (
        <Container>
            <Header />
            <div className="container">
                <Row>
                    <Col xs={12} className="mb-4">
                        <center className="mb-3">
                            <h3>Editar Cuenta</h3>
                            <p>Modificar cualquier detalle relacionado a tu cuenta</p>
                        </center>
                        <hr />
                    </Col>
                    <Col xs={{ size: 12, order: 2 }} md={{ size: 3, order: 1 }}>
                        <AccountMenu />
                    </Col>
                    <Col xs={{ size: 12, order: 1 }} md={{ size: 9, order: 2 }}>
                        <form onSubmit={handleSubmit}>
                            <FormContainer>
                                {loading ? (
                                    <LoaderAbsolute />
                                ) : (
                                    <React.Fragment>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Input
                                                    label="Nombre"
                                                    type="first_name"
                                                    id="first_name"
                                                    required
                                                    value={data.first_name}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Input
                                                    label="Apellidos"
                                                    type="last_name"
                                                    id="last_name"
                                                    required
                                                    value={data.last_name}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Input
                                            label="Correo Electrónico"
                                            type="email"
                                            id="email"
                                            placeholder="heybeauty@lacajarosa.com"
                                            required
                                            value={data.email}
                                            onChange={handleChange}
                                        />

                                        <Input
                                            label="Teléfono"
                                            type="phone"
                                            id="phone"
                                            required
                                            value={data.phone}
                                            onChange={handleChange}
                                        />

                                        <Input
                                            label={
                                                <>
                                                    <i className="low-opacity mr-1 fab fa-instagram"></i> Tu usuario en
                                                    Instagram ✨
                                                </>
                                            }
                                            id="instagram_username"
                                            type="text"
                                            value={data.instagram_username}
                                            placeholder="@lacajarosa"
                                            onChange={handleChange}
                                        />
                                        <hr />
                                        <h5>Dirección de Envío</h5>
                                        <Input
                                            label="Dirección de envío"
                                            type="address"
                                            id="address_1"
                                            value={data.address_1}
                                            onChange={handleChange}
                                            required
                                        />
                                        <Input
                                            label="Referencias?"
                                            placeholder="Alguna referencia para ubicarnos mucho mejor."
                                            type="address"
                                            id="address_2"
                                            value={data.address_2}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            label="Sector"
                                            type="text"
                                            id="sector"
                                            value={data.sector}
                                            onChange={handleChange}
                                            required
                                        />
                                        <Select
                                            onChange={handleChange}
                                            label="Provincia"
                                            id="province"
                                            value={data.province}
                                            required>
                                            {provinces.map((e, key) => (
                                                <option key={key} value={e.id}>
                                                    {e.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </React.Fragment>
                                )}
                            </FormContainer>
                            {!loading && (
                                <div className="flex-right">
                                    <Button primary>Guardar cambios</Button>
                                </div>
                            )}
                        </form>
                    </Col>
                </Row>
            </div>
            <Footer />
        </Container>
    )
}
const Container = styled.div``
const FormContainer = styled.div`
    position: relative;
`
export default withContext(MiCuenta)
