import React from 'react'
import styled from 'styled-components'
import Brands from '../quiz/brands'
import Swatch from '../quiz/swatch'
import Questions from '../quiz/questions'
import BigChoices from '../quiz/big_choices'
import CheckboxQuiz from '../quiz/checkbox_quiz'

function Quiz(props) {
    const { MultipleSelect, steps, data, changeSwatch, options, lang } = props
    return (
        <Container>
            {steps === 1 && (
                <section id="skin_tone">
                    <center className="mb-6">
                        <h3>{lang.what_is_your_skin_tone}</h3>
                    </center>
                    <Swatch
                        dataMap={options}
                        id="skin_tone"
                        handleChange={changeSwatch}
                        value={data.skin_tone}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 2 && (
                <section id="skin_type">
                    <center className="mb-6">
                        <h3>{lang.what_is_your_skin_type}</h3>
                    </center>
                    <Questions
                        dataMap={options}
                        id="skin_type"
                        handleChange={changeSwatch}
                        value={data.skin_type}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 3 && (
                <section id="eyes_color">
                    <center className="mb-6">
                        <h3>{lang.what_is_the_color_of_your_eyes}</h3>
                    </center>
                    <Swatch
                        dataMap={options}
                        id="eyes_color"
                        handleChange={changeSwatch}
                        value={data.eyes_color}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 4 && (
                <section id="hair_color">
                    <center className="mb-6">
                        <h3>{lang.what_is_your_hair_color}</h3>
                    </center>
                    <Swatch
                        dataMap={options}
                        id="hair_color"
                        handleChange={changeSwatch}
                        value={data.hair_color}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 5 && (
                <section id="hair_texture">
                    <center className="mb-6">
                        <h3>{lang.how_would_you_describe_your_hair}</h3>
                    </center>
                    <BigChoices
                        single
                        dataMap={options}
                        id="hair_texture"
                        handleChange={changeSwatch}
                        value={data.hair_texture}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 6 && (
                <section id="hair_handle">
                    <center className="mb-6">
                        <h3>{lang.how_do_you_handle_your_hair}</h3>
                    </center>
                    <Questions
                        dataMap={options}
                        id="hair_handle"
                        handleChange={changeSwatch}
                        value={data.hair_handle}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 7 && (
                <section id="enamel_tone">
                    <center className="mb-6">
                        <h3>{lang.what_nail_polish_shades_do_you_prefer}</h3>
                    </center>
                    <Questions
                        dataMap={options}
                        id="enamel_tone"
                        handleChange={changeSwatch}
                        value={data.enamel_tone}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 8 && (
                <section id="makeup_use">
                    <center className="mb-6">
                        <h3>{lang.how_comfortable_are_you_with_makeup}</h3>
                    </center>
                    <Questions
                        dataMap={options}
                        id="makeup_use"
                        handleChange={changeSwatch}
                        value={data.makeup_use}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 9 && (
                <section id="skin_routine">
                    <center className="mb-6">
                        <h3>{lang.how_is_your_beauty_routine}</h3>
                    </center>
                    <Questions
                        dataMap={options}
                        id="skin_routine"
                        handleChange={changeSwatch}
                        value={data.skin_routine}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 10 && (
                <section id="stores">
                    <center className="mb-6">
                        <h3>{lang.where_do_you_shop}</h3>
                        <p>{lang.you_can_choose_one_or_more_stores}</p>
                    </center>
                    <Brands
                        id="stores"
                        dataMap={props.stores}
                        handleChange={MultipleSelect}
                        value={data.stores}
                        data={data}
                        showName
                        lang={lang}
                    />
                </section>
            )}
            {steps === 11 && (
                <section id="beauty_brands">
                    <center className="mb-6">
                        <h3>{lang.which_of_these_brands_do_you_love}</h3>
                        <p>{lang.you_can_choose_one_or_more_brands}</p>
                    </center>
                    <Brands
                        id="beauty_brands"
                        dataMap={props.brands}
                        handleChange={MultipleSelect}
                        value={data.beauty_brands}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 12 && (
                <section id="prefered_makeup">
                    <center className="mb-6">
                        <h3>{lang.what_products_would_you_like_to_get_more_of}</h3>
                        <p>{lang.you_can_choose_more_than_one}</p>
                    </center>
                    <CheckboxQuiz
                        dataMap={options}
                        id="prefered_makeup"
                        handleChange={MultipleSelect}
                        value={data.prefered_makeup}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 13 && (
                <section id="personal_care">
                    <center className="mb-6">
                        <h3>{lang.which_personal_care_products_do_you_want_to_recieve}</h3>
                        <p>{lang.you_can_choose_more_than_one}</p>
                    </center>
                    <CheckboxQuiz
                        dataMap={options}
                        id="personal_care"
                        handleChange={MultipleSelect}
                        value={data.personal_care}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 14 && (
                <section id="care_products">
                    <center className="mb-6">
                        <h3>{lang.which_care_products_do_you_want_to_receive}</h3>
                        <p>{lang.you_can_choose_more_than_one}</p>
                    </center>
                    <CheckboxQuiz
                        dataMap={options}
                        id="care_products"
                        handleChange={MultipleSelect}
                        value={data.care_products}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
            {steps === 15 && (
                <section id="products_type">
                    <center className="mb-6">
                        <h3>{lang.which_kind_of_products_you_will_like_to_receive}</h3>
                    </center>
                    <Questions
                        dataMap={options}
                        id="products_type"
                        handleChange={changeSwatch}
                        value={data.products_type}
                        data={data}
                        lang={lang}
                    />
                </section>
            )}
        </Container>
    )
}
const Container = styled.div``
export default Quiz
