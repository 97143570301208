import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { withContext } from '../../context/withContext'
import { Header, Footer } from '../../layouts'
import AccountMenu from '../../components/elements/AccountMenu'
import ViewQuiz from '../../components/account/ViewQuiz'

function QuizView(props) {
    const token = props.context.token
    const user = props.context.user
    return (
        <Container>
            <Header />
            <div className="container">
                <Row>
                    <Col xs={12} className="mb-4">
                        <center className="mb-3">
                            <h3 className="pink">Beauty Quiz</h3>
                            <p>Todo sobre el Beauty Quiz que llenaste.</p>
                        </center>
                        <hr />
                    </Col>
                    <Col xs={{ size: 12, order: 2 }} md={{ size: 3, order: 1 }}>
                        <AccountMenu />
                    </Col>
                    <Col xs={{ size: 12, order: 1 }} md={{ size: 9, order: 2 }}>
                        <div className="flex-right mb-2">
                            <Link to={`/mi-cuenta/quiz/edit/${user.quiz}`} className="pink">
                                <i className="fas fa-pen-square"></i> Editar mi <strong>Beauty Quiz</strong>
                            </Link>
                        </div>
                        <ViewQuiz token={token} quiz={user.quiz} />
                    </Col>
                </Row>
            </div>
            <Footer />
        </Container>
    )
}
const Container = styled.div``
export default withContext(QuizView)
