import React from 'react'

function UnderMaintenance(props) {
    return (
        <center>
            <img
                src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2020/4/7Shte4cdNr-undraw_maintenance_cn7j.svg"
                height="200px"
                className="mb-6"
            />
            <div style={{ maxWidth: '400px' }}>
                <h4>Sección en mantenimiento</h4>
                <p>Estamos haciendo cambios en esta sección para que podamos ofrecer un mejor servicio.</p>
                <p>(809) 362 2442 | hello@lacajarosa.com</p>
            </div>
        </center>
    )
}
export default UnderMaintenance
