import React from 'react'
import styled from 'styled-components'

const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

function StepsCircle(props) {
    return (
        <Container>
            {steps.map((e, key) => (
                <li key={key} className={props.active === e && 'active'}></li>
            ))}
        </Container>
    )
}
const Container = styled.ul`
    padding: 0;
    margin: 20px 0px 0px 0px;
    text-align: center;
    li {
        list-style: none;
        display: inline-block;
        margin: 0 4px;
        overflow: hidden;
        height: 8px;
        width: 8px;
        border-radius: 4px;
        cursor: pointer;
        background-color: #ddd;
        background-size: unset;
        background-position: 800px 800px;
        background-repeat: no-repeat;
    }
    .active {
        background: #dd637e;
    }
`
export default StepsCircle
