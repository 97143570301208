import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { Intent } from '@blueprintjs/core'
import Cookies from 'universal-cookie'
import moment from 'moment'
// local import
import { Header, Footer } from '../layouts'
import { Button } from '../components/elements/Button'
import StepsCircle from '../components/elements/StepsCircles'
import Questions from '../components/quiz_steps/questions'
import Checkout from '../components/quiz_steps/checkout'
import Register from '../components/quiz_steps/register'
import { GetOptions, SubmitQuiz } from '../api/quizes'
import { GetBrands, GetStores } from '../api/products'
import { GetProvinces } from '../api/core'
import { OurToaster } from '../components/OurToaster'
import { withContext } from '../context/withContext'
import { ViewPlan } from '../api/subscriptions'
import ThankYou from '../components/quiz_steps/thank_you'
import { Mixpanel } from '../components/Mixpanel'

const cookies = new Cookies()

function Quiz(props) {
    const [data, setData] = useState({
        stores: [],
        beauty_brands: [],
        prefered_makeup: [],
        personal_care: [],
        care_products: [],
        plan: '4',
    })
    const { user, completed_quiz, is_subscribed, language } = props.context
    const lang = require(`../config/i18n/${language}.json`)

    // arrays
    const [brands, setBrands] = useState([])
    const [stores, setStores] = useState([])
    const [options, setOptions] = useState([])
    const [provinces, setProvinces] = useState([])
    const [cities, setCities] = useState([])
    const [sectors, setSectors] = useState([])
    // bools and ints
    const [steps, setStep] = useState(1)
    const [loading, setLoader] = useState(false)
    const [loadingForm, setLoaderForm] = useState(false)
    const [activeKey, setActiveKey] = useState('skin_tone')

    useEffect(() => {
        RetrieveOptions()

        let q = queryString.parse(props.history.location.search)
        if (q.plan && typeof data.price === 'undefined') {
            // Mixpanel config
            Mixpanel.track('Loaded Quiz', { Plan: '5' })
            RetrievePlan(q.plan)
        } else {
            Mixpanel.track('Loaded Quiz', { Plan: 'Unkown' })
        }
        if (completed_quiz && is_subscribed) {
            // Si esta loggeado, completo quiz y tiene suscripción
            console.log('Completed and Subscribed')
            props.history.push('/mi-cuenta/quiz')
            return
        }
        // handle events if user is logged in but its not subscribed
        if (completed_quiz && !is_subscribed) {
            console.log('Completed and not checkout')
            let newData = Object.assign({}, data)
            newData.email = user.email
            setData(newData)
            setStep(16)
        }
        console.log(user, completed_quiz, is_subscribed)
        // setData(data)
    }, [user.uid])

    const RetrieveOptions = async () => {
        try {
            let r1 = await GetOptions()
            let r2 = await GetStores()
            let r3 = await GetBrands()
            let r4 = await GetProvinces()
            // let r5 = await GetPlan()
            setOptions(r1.data)
            setStores(r2.data)
            setBrands(r3.data)
            setProvinces(r4.data)
        } catch (error) {
            console.log(error)
        }
    }

    const RetrievePlan = async (planId) => {
        let newData = Object.assign({}, data)
        newData.plan = planId
        try {
            let res = await ViewPlan(planId)
            console.log(res.data)
            newData.plan_name = res.data.name
            newData.price = res.data.price
            setData(newData)
        } catch (error) {
            console.log(error)
        }
    }

    const changeSwatch = (akey, e) => {
        let newData = Object.assign({}, data)
        newData[akey] = e.id
        setData(newData)
        // setTimeout(() => {
        //     setStep(steps + 1)
        // }, 800)
        window.scrollTo(0, 0)
        CheckStepPropgress()
    }

    const handleNextButton = (akey, step) => {
        window.scrollTo(0, 0)
        let more = 0
        if (steps === 5) more = 1
        if (steps === 11) more = 3
        if (steps === 1 && !data.skin_tone) return alert(lang.please_choose_at_least_1_option)
        if (steps === 2 && !data.skin_type) return alert(lang.please_choose_at_least_1_option)
        if (steps === 3 && !data.eyes_color) return alert(lang.please_choose_at_least_1_option)
        if (steps === 4 && !data.hair_color) return alert(lang.please_choose_at_least_1_option)
        if (steps === 5 && !data.hair_texture) return alert(lang.please_choose_at_least_1_option)
        // if (steps === 6 && !data.hair_handle) return alert(lang.please_choose_at_least_1_option)
        if (steps === 7 && !data.enamel_tone) return alert(lang.please_choose_at_least_1_option)
        if (steps === 8 && !data.makeup_use) return alert(lang.please_choose_at_least_1_option)
        if (steps === 9 && !data.skin_routine) return alert(lang.please_choose_at_least_1_option)
        if (steps === 10 && data.stores.length === 0) return alert(lang.please_choose_at_least_1_option)
        if (steps === 11 && data.beauty_brands.length === 0) return alert(lang.please_choose_at_least_1_option)
        if (steps === 12 && data.prefered_makeup.length === 0) return alert(lang.please_choose_at_least_1_option)
        if (steps === 13 && data.personal_care.length === 0) return alert(lang.please_choose_at_least_1_option)
        if (steps === 14 && data.care_products.length === 0) return alert(lang.please_choose_at_least_1_option)
        if (steps === 15 && data.products_type.length === 0) return alert(lang.please_choose_at_least_1_option)
        Mixpanel.track(`${lang.complted_question} ${steps}`, { Plan: data.plan, plan_name: data.plan_name, step: steps })
        setStep(steps + 1 + more)
        CheckStepPropgress()
    }

    const handleBackButton = () => {
        let more = 0
        if (steps === 7) more = 1
        if (steps === 15) more = 3
        window.scrollTo(0, 0)
        setStep(steps - 1 - more)
    }

    const MultipleSelect = (akey, e) => {
        let newData = Object.assign({}, data)
        if (newData[akey]) {
            if (newData[akey].includes(e.id)) {
                let index = newData[akey].indexOf(e.id)
                newData[akey].splice(index, 1)
            } else {
                newData[akey].push(e.id)
            }
        } else {
            newData[akey] = []
            newData[akey].push(e.id)
        }
        setData(newData)
        CheckStepPropgress()
    }

    const handleCheckbox = (index, e) => {
        let value = e.target.value
        if (data[index] && data[index] === value) {
            data[index] = null
        } else {
            data[index] = value
        }
        setData(data)
    }

    const handleChange = (e) => {
        let newData = Object.assign({}, data)
        let index = e.target.name
        let value = e.target.value
        if (index === '') index = 'birthdate'
        newData[index] = value
        if (index === 'province') {
            if (value === '31') {
                newData = RetrievePricingLocal(newData)
            } else {
                newData = RetrievePricingInterior(newData)
            }
        }
        setData(newData)
    }

    const RetrievePricingInterior = (newData) => {
        if (data.plan.toString() === '4') {
            newData.plan = '7'
            newData.price = 1190
            newData.plan_name = 'NAME_HERE'
        }
        if (data.plan.toString() === '5') {
            newData.plan = '8'
            newData.price = 3465
            newData.plan_name = 'NAME_HERE'
        }
        if (data.plan.toString() === '6') {
            newData.plan = '9'
            newData.price = 6690
            newData.plan_name = 'NAME_HERE'
        }
        return newData
    }

    const RetrievePricingLocal = (newData) => {
        if (data.plan.toString() === '7') {
            newData.plan = '4'
            newData.price = 990
            newData.plan_name = 'NAME_HERE'
        }
        if (data.plan.toString() === '8') {
            newData.plan = '5'
            newData.price = 2865
            newData.plan_name = 'NAME_HERE'
        }
        if (data.plan.toString() === '9') {
            newData.plan = '6'
            newData.price = 5490
            newData.plan_name = 'NAME_HERE'
        }
        return newData
    }

    const CheckStepPropgress = () => {
        // console.log(steps)
        if (steps > 13) {
            setLoader(true)
            setTimeout(() => {
                setLoader(false)
            }, 2000)
        }
        if (steps === 15) {
            Mixpanel.track('Loaded Signup', { Plan: data.plan })
            console.log('Ejecuta Registrar')
        }
        if (steps === 16) {
            Mixpanel.track('Loaded Checkout', { Plan: data.plan })
            console.log('Ejecuta Checkout')
        }
    }

    const SubmitQuizAndRegister = async (e) => {
        e.preventDefault()
        setLoaderForm(true)
        try {
            let response = await SubmitQuiz(data)
            cookies.set('token', response.data.token, { path: '/' })
            // Login user in platform
            let udata = {
                // MembersSerializerData
                user: response.data.user_data,
                token: response.data.token,
                isLogged: true,
            }
            Mixpanel.track('Completed Signup', { Plan: data.plan })
            props.updateContextVal(udata)
            setStep(17)
            setLoaderForm(false)
            data.quiz = response.data.id
            setData(data)
            window.scrollTo(0, 0)
        } catch (error) {
            Mixpanel.track('Error at Signup Process', { Plan: data.plan, response: lang.email_already_exists })
            OurToaster.show({
                message: lang.email_already_registered_message,
                intent: Intent.DANGER,
                icon: 'danger',
            })
            setLoader(false)
            setStep(15)
            setLoaderForm(false)
        }
    }

    const handleDateChange = (e) => {
        let newData = Object.assign({}, data)
        newData.birthdate = moment(e).format('YYYY-MM-DD')
        setData(newData)
    }

    return (
        <Container className="wrapper">
            <Header />
            <p className="text-center">
                {lang.complete_this_quiz}
            </p>
            {steps >= 1 && steps <= 15 && <StepsCircle active={steps} />}
            <div className="container">
                <Questions
                    options={options}
                    brands={brands}
                    stores={stores}
                    handleChange={handleChange}
                    MultipleSelect={MultipleSelect}
                    handleCheckbox={handleCheckbox}
                    changeSwatch={changeSwatch}
                    steps={steps}
                    data={data}
                    lang={lang}
                />
                {steps === 16 && (
                    <Register
                        data={data}
                        loading={loading}
                        loadingForm={loadingForm}
                        handleChange={handleChange}
                        SubmitQuizAndRegister={SubmitQuizAndRegister}
                        handleDateChange={handleDateChange}
                        handleCheckbox={handleCheckbox}
                        lang={lang}
                    />
                )}
            </div>
            {steps === 17 && (
                <Checkout
                    cities={cities}
                    provinces={provinces}
                    sectors={sectors}
                    data={data}
                    loading={loading}
                    loadingForm={loadingForm}
                    handleChange={handleChange}
                    setLoader={() => setLoader(!loading)}
                    setLoaderForm={() => setLoaderForm(!loadingForm)}
                    setLoaderFormFalse={() => setLoaderForm(false)}
                    changeStep={() => setStep(18)}
                    lang={lang}
                />
            )}
            {steps === 18 && (
                <ThankYou
                    cities={cities}
                    provinces={provinces}
                    sectors={sectors}
                    data={data}
                    loading={loading}
                    loadingForm={loadingForm}
                    handleChange={handleChange}
                />
            )}
            {steps <= 15 && (
                <ButtonContainer>
                    {steps > 1 && (
                        <Button ghost onClick={handleBackButton}>
                            {lang.previous}
                        </Button>
                    )}
                    <Button primary onClick={() => handleNextButton(activeKey, steps)}>
                        {lang.next}
                    </Button>
                </ButtonContainer>
            )}
            <Footer />
        </Container>
    )
}
const Container = styled.div`
    .title-maxed {
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 2.5rem;
    }
    h3 {
        font-weight: 600;
    }
`
const ButtonContainer = styled.div`
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 1rem;
    button {
        display: inline-block;
        margin-right: 15px;
    }
    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: row;
        button {
            width: 50%;
            margin: 0;
            padding: 15px;
        }
    }
`

export default withRouter(withContext(Quiz))
