import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FeatherIcons from 'feather-icons-react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Loader from 'react-loader-spinner'
import { Intent } from '@blueprintjs/core'
// local
import { withContext } from '../context/withContext'
import { Header, Footer } from '../layouts'
import { Input } from '../components/FormGroups'
import { Button } from '../components/elements/Button'
import { API } from '../utils/config'
import { OurToaster } from '../components/OurToaster'
import { Mixpanel } from '../components/Mixpanel'
const cookies = new Cookies()

function Login(props) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loading, showLoading] = useState(false)

    useEffect(() => {
        if (props.context.isLogged) {
            props.history.push('/mi-cuenta')
        }
    }, [props.context.isLogged])

    const handleLogin = (e) => {
        e.preventDefault()
        showLoading(true)
        API.post('/login/', { username: username, password: password })
            .then((response) => {
                cookies.set('token', response.data.token, { path: '/' })
                let data = {
                    user: response.data.user,
                    token: response.data.token,
                    isLogged: true,
                }
                Mixpanel.track('Login Successful')
                props.updateContextVal(data)
                showLoading(false)
                props.history.push('/mi-cuenta')
            })
            .catch((err) => {
                Mixpanel.track('Error al Iniciar Sesión')
                OurToaster.show({ message: 'Verifica los datos e intenta de nuevo.', intent: Intent.DANGER })
                showLoading(false)
            })
    }
    return (
        <div>
            <Header />
            <Container>
                <center className="mb-6 mt-8">
                    <FeatherIcons size={45} icon="lock" className="mb-3" />
                    <h3>Iniciar sesión</h3>
                    <p>Accede a tu cuenta para hacer cualquier modificación necesaria.</p>
                </center>
                <form onSubmit={handleLogin}>
                    <Input
                        required
                        id="email"
                        type="email"
                        value={username}
                        label="Correo electrónico"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <Input
                        required
                        id="password"
                        type="password"
                        value={password}
                        label="Contraseña"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button primary className="w-100" disabled={loading} block>
                        {loading ? <Loader type="ThreeDots" color="#FFF" height={10} width={100} /> : 'Iniciar Sesión'}
                    </Button>
                </form>
                <center className="mt-4">
                    <Link to={'/olvide-contrasena'}>¿Olvidaste tu contraseña?</Link>
                </center>
            </Container>
            <Footer />
        </div>
    )
}
const Container = styled.div`
    max-width: 550px;
    margin: 0 auto;
    button {
        width: 100% !important;
    }
`
export default withContext(Login)
