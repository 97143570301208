import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Spinner } from '@blueprintjs/core'
import { Row, Col } from 'reactstrap'
import { loadStripe } from '@stripe/stripe-js'
import NumberFormat from 'react-number-format'
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js'
import Loader from 'react-loader-spinner'
// local import
import { Input, Select } from '../FormGroups'
import { Button } from '../elements/Button'
import { NewSubscription } from '../../api/subscriptions'
import { stripeKEY } from '../../utils/config'
import { Mixpanel } from '../Mixpanel'

const stripePromise = loadStripe(stripeKEY)

function CheckoutForm(props) {
    const [paymentEnabled, enablePayment] = useState(false)
    const { data, provinces, lang } = props
    const stripe = useStripe()
    const elements = useElements()
    useEffect(() => {
        Mixpanel.track('Loaded Checkout', { Plan: data.plan })
    }, [])

    const handlePayment = async (event) => {
        props.setLoaderForm()
        event.preventDefault()
        if (!stripe || !elements) {
            props.setLoaderForm()
            alert('Verifica los datos de tu tarjeta de crédito que estén completos.')
            return
        }
        const card = elements.getElement(CardElement)
        const result = await stripe.createToken(card)
        if (result.error) {
            props.setLoaderFormFalse()
            alert(result.error.message)
            console.log(result.error.message)
        } else {
            stripeTokenSubscriptionHandler(result.token)
        }
    }

    const stripeTokenSubscriptionHandler = async (token) => {
        let newData = Object.assign({}, data)
        newData['paymentMethod'] = token
        try {
            // Register subscription on stripe
            await NewSubscription(newData)
            window.scrollTo(0, 0)
            props.changeStep()
            props.setLoaderFormFalse()
            Mixpanel.track('New Subscription', { Plan: data.plan })
        } catch (error) {
            props.setLoaderFormFalse()
            console.log(error)
        }
    }

    const handleCardChange = (e) => {
        if (e.complete) {
            enablePayment(true)
        } else {
            enablePayment(false)
        }
    }
    return (
        <section id="checkout_step_2">
            <form onSubmit={handlePayment}>
                {props.loading ? (
                    <div className="container">
                        <center>
                            <h4>
                                {lang.welcome_to}<strong className="pink">{props.data.first_name}</strong> La Caja Rosa! 💖
                            </h4>
                            <Spinner size={45} intent="primary" className="pt-6 pb-6" />
                        </center>
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="container">
                            <center className="mb-6">
                                <h3>{lang.shipping_address}</h3>
                                <p>{lang.please_indicate_where_to_send_the_box}</p>
                            </center>
                            <Row>
                                <Col xs={12} md={{ size: 6, offset: 3 }}>
                                    <Input
                                        label={lang.address}
                                        type="address"
                                        id="address_1"
                                        value={data.address_1}
                                        onChange={props.handleChange}
                                        required
                                    />
                                    <Input
                                        label={lang.address_2}
                                        placeholder={lang.address_2_placeholder}
                                        type="address"
                                        id="address_2"
                                        value={data.address_2}
                                        onChange={props.handleChange}
                                    />
                                    <Input
                                        label={lang.address_city}
                                        type="text"
                                        id="sector"
                                        value={data.sector}
                                        onChange={props.handleChange}
                                        required
                                    />
                                    <Select
                                        onChange={props.handleChange}
                                        label={lang.address_state}
                                        id="province"
                                        value={data.province}
                                        required>
                                        {provinces.map((e, key) => (
                                            <option key={key} value={e.id}>
                                                {e.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                        </div>
                        <div className="pink-bg mt-4 mb-6 py-4">
                            <div className="container">
                                <Row>
                                    <Col xs={12}>
                                        <center>
                                            <h4>{lang.secure_payment}</h4>
                                            <p className="p0">
                                                <small>
                                                    {lang.secure_payment_notice}
                                                </small>
                                            </p>
                                        </center>
                                    </Col>
                                    <Col xs={12} md={{ size: 6, offset: 3 }}>
                                        <center>
                                            <Cards>
                                                <li>
                                                    <i className="fab fa-cc-visa"></i>
                                                </li>
                                                <li>
                                                    <i className="fab fa-cc-discover"></i>
                                                </li>
                                                <li>
                                                    <i className="fab fa-cc-mastercard"></i>
                                                </li>
                                                <li>
                                                    <i className="fab fa-cc-amex"></i>
                                                </li>
                                            </Cards>
                                        </center>
                                        <fieldset className="FormGroup">
                                            <div className="FormRow">
                                                <CardElement onChange={handleCardChange} />
                                            </div>
                                        </fieldset>
                                        <Price>
                                            <small className="d-block low-opacity">{lang.total_to_pay}</small>{' '}
                                            <strong className="d-block">
                                                <NumberFormat
                                                    displayType="text"
                                                    prefix={'RD$ '}
                                                    value={data.price}
                                                    thousandSeparator
                                                />
                                            </strong>
                                        </Price>
                                        <p className="text-center mt-4">{lang.total_summary}</p>
                                        <table
                                            className="bp3-html-table w-100 bp3-html-table-bordered bp3-html-table-condensed bp3-interactive bp3-small"
                                            style={{ border: '1px solid #eee' }}>
                                            <thead>
                                                <tr>
                                                    <th>{lang.description}</th>
                                                    <th>{lang.amount}</th>
                                                </tr>
                                            </thead>
                                            {data.plan === '4' && (
                                                <tbody>
                                                    <tr>
                                                        <td>Plan Rose</td>
                                                        <td>RD$ 990</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{lang.shipping}</td>
                                                        <td>{lang.FREE}</td>
                                                    </tr>
                                                </tbody>
                                            )}
                                            {data.plan === '5' && (
                                                <tbody>
                                                    <tr>
                                                        <td>Plan Fucsia</td>
                                                        <td>RD$ 2,865</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{lang.shipping}</td>
                                                        <td>{lang.FREE}</td>
                                                    </tr>
                                                </tbody>
                                            )}
                                            {data.plan === '6' && (
                                                <tbody>
                                                    <tr>
                                                        <td>Plan Rose Gold</td>
                                                        <td>RD$ 5,490</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{lang.shipping}</td>
                                                        <td>{lang.FREE}</td>
                                                    </tr>
                                                </tbody>
                                            )}
                                            {data.plan === '7' && (
                                                <tbody>
                                                    <tr>
                                                        <td>Plan Rose</td>
                                                        <td>RD$ 990</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{lang.shipping}</td>
                                                        <td>RD$ 200</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>
                                                            <strong>RD$ 1,190</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                            {data.plan === '8' && (
                                                <tbody>
                                                    <tr>
                                                        <td>Plan Fucsia</td>
                                                        <td>RD$ 2,865</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{lang.shipping} (RD$ 200 x 3 {lang.months})</td>
                                                        <td>RD$ 600</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>
                                                            <strong>RD$ 3,465</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                            {data.plan === '9' && (
                                                <tbody>
                                                    <tr>
                                                        <td>Plan Rose Gold</td>
                                                        <td>RD$ 5,490</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{lang.shipping} (RD$ 200 x 6 {lang.months})</td>
                                                        <td>RD$ 1,200</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>
                                                            <strong>RD$ 6,690</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </table>
                                        <div className="text-center mt-3">
                                            <a href="https://lacajarosa.com/edicion-del-mes#planes-edicion">
                                                {lang.change_plan}
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="container text-center">
                            <Row>
                                <Col xs={12} md={{ size: 6, offset: 3 }}>
                                    <CompleteDiv>
                                        <p>
                                            <small className="low-opacity">
                                                {lang.subscription_agreement}{' '}
                                                <a href="https://lacajarosa.com/terminos-y-condiciones" target="_blank">
                                                    {lang.term_of_service}
                                                </a>
                                                {lang.subscription_agreement_2}
                                            </small>
                                        </p>
                                        {props.loadingForm ? (
                                            <Button
                                                primary
                                                disabled={true}
                                                disableReason={lang.processing_order}>
                                                <Loader type="ThreeDots" color="#FFF" height={10} width={100} />
                                            </Button>
                                        ) : (
                                            <Button
                                                primary
                                                disabled={!paymentEnabled || !stripe}
                                                disableReason={lang.verify_credit_card}>
                                                Suscribirse
                                            </Button>
                                        )}
                                    </CompleteDiv>
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                )}
            </form>
        </section>
    )
}

const Checkout = (props) => (
    <Elements stripe={stripePromise}>
        <CheckoutForm {...props} />
    </Elements>
)
const CompleteDiv = styled.div`
    p {
        text-align: justify;
    }
`
const Price = styled.div`
    color: #000;
    text-align: center;
    small {
        text-transform: uppercase;
    }
    strong {
        font-size: 1.5rem;
    }
`
const Cards = styled.ul`
    width: 100%;
    padding: 0;
    list-style: none;
    padding-bottom: 1rem;
    li {
        opacity: 0.5;
        font-size: 1.1rem;
        margin-right: 15px;
        display: inline-block;
        :last-child {
            margin-right: 0;
        }
        i {
            font-size: 2.5rem;
        }
    }
`
export default withRouter(Checkout)
