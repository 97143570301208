import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { Intent } from '@blueprintjs/core'
// local import
import { Button } from '../../components/elements/Button'
import { Header, Footer } from '../../layouts'
import AccountMenu from '../../components/elements/AccountMenu'
import { Input } from '../../components/FormGroups'
import { withContext } from '../../context/withContext'
import { ChangeMyPassword } from '../../api/core'
import { OurToaster } from '../../components/OurToaster'

function ChangePassword(props) {
    const user = props.context.user
    const [loading, setLoader] = useState(false)
    const [data, setData] = useState({
        email: user.email,
        uid: user.uid,
    })
    const token = props.context.token

    const SaveSettings = (e) => {
        e.preventDefault()
        setLoader(true)
        ChangeMyPassword(token, data)
            .then((response) => {
                setLoader(false)
                OurToaster.show({ message: 'Contraseña cambiada exitosamente.', intent: Intent.SUCCESS, icon: 'tick' })
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500)
            })
            .catch((err) => {
                setLoader(false)
                OurToaster.show({ message: 'Verifica los datos e intentalo de nuevo.', intent: Intent.DANGER })
            })
    }

    const handleChange = (e) => {
        let index = e.target.getAttribute('id')
        data[index] = e.target.value
        console.log(data)
        setData({ ...data })
    }
    return (
        <Container>
            <Header />
            <div className="container">
                <Row>
                    <Col xs={12} className="mb-4">
                        <center className="mb-3">
                            <h3>Cambiar Contraseña</h3>
                            <p>Realiza cambios en la contraseña de tu cuenta</p>
                        </center>
                        <hr />
                    </Col>
                    <Col xs={{ size: 12, order: 2 }} md={{ size: 3, order: 1 }}>
                        <AccountMenu />
                    </Col>
                    <Col xs={{ size: 12, order: 1 }} md={{ size: 9, order: 2 }}>
                        <form onSubmit={SaveSettings}>
                            <p>Realiza el cambio de tu contraseña colocando la contraseña anterior y luego la nueva.</p>
                            <Input
                                required={true}
                                label="Contraseña Actual"
                                id="old_password"
                                type="password"
                                onChange={handleChange}
                            />
                            <Input
                                required={true}
                                label="Nueva Contraseña"
                                id="password"
                                type="password"
                                onChange={handleChange}
                            />
                            <Input
                                required={true}
                                label="Confirmar nueva contraseña"
                                id="confirm_password"
                                type="password"
                                onChange={handleChange}
                            />
                            <div className="flex-right">
                                <Button primary>Guardar cambios</Button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </div>
            <Footer />
        </Container>
    )
}
const Container = styled.div``
export default withContext(ChangePassword)
