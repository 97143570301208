import { API } from '../utils/config'

export function ValidateUser(token) {
    if (token) {
        return API('/validateUser/', { headers: { key: token } })
    } else {
        return API('/validateUser/')
    }
}
export function ValidateToken(token) {
    return API.post('/validateToken/', { token: token })
}

export function ViewEditMember(token, uid, data) {
    if (data) {
        return API.patch(`/members/edit/${uid}/`, data, { headers: { Authorization: 'Token ' + token } })
    } else {
        return API(`/members/edit/${uid}/`, { headers: { Authorization: 'Token ' + token } })
    }
}
export function ChangeMyPassword(token, data) {
    return API.patch('/changePassword/', data, { headers: { Authorization: 'Token ' + token } })
}
export function GetProvinces() {
    return API('/provinces/')
}
export function GetCities(qs) {
    return API(`/cities/?province=${qs}`)
}
export function GetSectors(qs) {
    return API(`/sectors/?city=${qs}`)
}
