import React from 'react'
import { UserConsumer } from './UserProvider'

export const withContext = (Component) => {
    return (props) => (
        <UserConsumer>
            {({ state, updateContextVal, changeLanguage }) => (
                <Component {...props} context={state} updateContextVal={updateContextVal} changeLanguage={changeLanguage} />
            )}
        </UserConsumer>
    )
}
