import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Spinner } from '@blueprintjs/core'
import moment from 'moment'
import { Link } from 'react-router-dom'

function Thankyou(props) {
    const { data } = props
    return (
        <section id="checkout_step_2">
            {props.loading ? (
                <div className="container">
                    <center>
                        <h4>
                            <strong className="pink">
                                Hello Beauty! <span role="img">😍</span>
                            </strong>
                        </h4>
                        <p>
                            Estamos procesando tu orden, en unos segundos todo estará listo. <span role="img">✨</span>
                        </p>
                        <Spinner size={45} intent="primary" className="pt-6 pb-6" />
                    </center>
                </div>
            ) : (
                <React.Fragment>
                    <div className="container">
                        <Container>
                            <center className="mb-6">
                                <img
                                    src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2020/4/dkTCLEbkzu-5m7cmlQ.gif"
                                    height="200"
                                    className="pb-3"
                                    style={{ borderRadius: '5px' }}
                                />
                                <h3>
                                    ¡Bienvenida a <span className="pink">La Caja Rosa</span>!
                                    <span className="d-block">Tu Suscripción ya está activa.</span>
                                </h3>
                                <p>
                                    Debajo encontrarás toda la información acerca de tu suscripción. Si tienes alguna
                                    pregunta no dudes en escribirnos.
                                </p>
                            </center>
                            <hr />
                            <Summary>
                                <h4>Resumen de tu orden</h4>
                                <table
                                    className="bp3-html-table w-100 bp3-html-table-bordered bp3-html-table-condensed bp3-interactive bp3-small"
                                    style={{ border: '1px solid #eee' }}>
                                    <thead>
                                        <tr>
                                            <th>Descripción</th>
                                            <th>Monto</th>
                                        </tr>
                                    </thead>
                                    {data.plan === '4' && (
                                        <tbody>
                                            <tr>
                                                <td>Plan Rose</td>
                                                <td>RD$ 990</td>
                                            </tr>
                                            <tr>
                                                <td>Envío</td>
                                                <td>GRATIS</td>
                                            </tr>
                                        </tbody>
                                    )}
                                    {data.plan === '5' && (
                                        <tbody>
                                            <tr>
                                                <td>Plan Fucsia</td>
                                                <td>RD$ 2,865</td>
                                            </tr>
                                            <tr>
                                                <td>Envío</td>
                                                <td>GRATIS</td>
                                            </tr>
                                        </tbody>
                                    )}
                                    {data.plan === '6' && (
                                        <tbody>
                                            <tr>
                                                <td>Plan Rose Gold</td>
                                                <td>RD$ 5,490</td>
                                            </tr>
                                            <tr>
                                                <td>Envío</td>
                                                <td>GRATIS</td>
                                            </tr>
                                        </tbody>
                                    )}
                                    {data.plan === '7' && (
                                        <tbody>
                                            <tr>
                                                <td>Plan Rose</td>
                                                <td>RD$ 990</td>
                                            </tr>
                                            <tr>
                                                <td>Envío</td>
                                                <td>RD$ 200</td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td>
                                                    <strong>RD$ 1,190</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                    {data.plan === '8' && (
                                        <tbody>
                                            <tr>
                                                <td>Plan Fucsia</td>
                                                <td>RD$ 2,865</td>
                                            </tr>
                                            <tr>
                                                <td>Envío (RD$ 200 x 3 meses)</td>
                                                <td>RD$ 600</td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td>
                                                    <strong>RD$ 3,465</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                    {data.plan === '9' && (
                                        <tbody>
                                            <tr>
                                                <td>Plan Rose Gold</td>
                                                <td>RD$ 5,490</td>
                                            </tr>
                                            <tr>
                                                <td>Envío (RD$ 200 x 6 meses)</td>
                                                <td>RD$ 1,200</td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td>
                                                    <strong>RD$ 6,690</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </Summary>
                            <hr />
                            <FaqContainer>
                                <h4>Ahora me imagino que tienes algunas preguntas...</h4>
                                <p className="pink">¿Cuando llega mi Caja Rosa?</p>
                                <p>
                                    Tranquila, no te preocupes. Tu caja llegará antes de <strong>2 semanas</strong>, si
                                    pasan más de 2 semanas puedes comunicarte con nosotras escribiéndonos un correo a{' '}
                                    <a href="mailto:info@lacajarosa.com">info@lacajarosa.com</a>
                                </p>
                                <p className="pink">¿Qué días me harán el cargo?</p>
                                {props.data.plan === '4' && (
                                    <p>
                                        Como tu plan seleccionado fue el "Rose", la Membresía a La Caja Rosa es Mensual,
                                        por tanto todos los días {moment().format('DD')} de cada mes se hará un cargo a
                                        tu tarjeta para cubrir La Caja de ese mes.
                                    </p>
                                )}
                                {props.data.plan === '5' && (
                                    <p>
                                        Como tu plan seleccionado fue el "Plan Fucsia" tu próximo cargo será el
                                        <strong className="d-block">
                                            {moment().add(3, 'months').format('DD-MM-YYYY')}
                                        </strong>
                                    </p>
                                )}
                                {props.data.plan === '6' && (
                                    <p>
                                        Como tu plan seleccionado fue el "Plan Rose Gold" tu próximo cargo será el
                                        <strong className="d-block">
                                            {moment().add(6, 'months').format('DD-MM-YYYY')}
                                        </strong>
                                    </p>
                                )}
                                {props.data.plan === '7' && (
                                    <p>
                                        Como tu plan seleccionado fue el "Rose", la Membresía a La Caja Rosa es Mensual,
                                        por tanto todos los días {moment().format('DD')} de cada mes se hará un cargo a
                                        tu tarjeta para cubrir La Caja de ese mes.
                                    </p>
                                )}
                                {props.data.plan === '8' && (
                                    <p>
                                        Como tu plan seleccionado fue el "Plan Fucsia" tu próximo cargo será el
                                        <strong className="d-block">
                                            {moment().add(3, 'months').format('DD-MM-YYYY')}
                                        </strong>
                                    </p>
                                )}
                                {props.data.plan === '9' && (
                                    <p>
                                        Como tu plan seleccionado fue el "Plan Rose Gold" tu próximo cargo será el
                                        <strong className="d-block">
                                            {moment().add(6, 'months').format('DD-MM-YYYY')}
                                        </strong>
                                    </p>
                                )}
                                <p className="pink">¿Qué productos estaré recibiendo?</p>
                                <p>
                                    <p>
                                        En base a las preguntas que acabas de responder en nuestro Beauty Quiz te
                                        enviaremos los productos que mejor se adaptan a tus necesidades. Por ello verás
                                        que a veces las cajas no son todas iguales: Nunca te enviaremos un producto para
                                        cabello rizado si lo tienes lacio.
                                    </p>

                                    <p>
                                        Los productos que recibirás con tu caja se adaptarán a tu perfil de belleza,
                                        pero no son necesariamente los que aparecen en las imágenes de esta página.
                                    </p>
                                    <p>
                                        Si quieres Editar tu Beauty Quiz puedes hacerlo entrando a tu cuenta o haciendo{' '}
                                        <Link to={'/mi-cuenta/quiz'}>click aquí.</Link>
                                    </p>
                                </p>
                            </FaqContainer>
                        </Container>
                    </div>
                </React.Fragment>
            )}
        </section>
    )
}
const Container = styled.div`
    max-width: 600px;
    margin: 0 auto;
    hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
`
const FaqContainer = styled.div`
    p {
        font-size: 16px;
        line-height: 1.8em;
    }
    p.pink {
        font-size: 18px;
        margin-top: 1rem;
        text-transform: uppercase;
        font-weight: 800;
    }
`
const Summary = styled.div``
export default withRouter(Thankyou)
