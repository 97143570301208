import React from 'react'
import styled from 'styled-components'
import { Checkbox, Spinner } from '@blueprintjs/core'
import { Row, Col } from 'reactstrap'
import NumberFormat from 'react-number-format'
import Loader from 'react-loader-spinner'
import { DateInput } from '@blueprintjs/datetime'
import moment from 'moment'
// local import
import { Input } from '../FormGroups'
import { Button } from '../elements/Button'

function Register(props) {
    const { data, handleDateChange, lang } = props
    var d = new Date()
    var year = d.getFullYear()
    var month = d.getMonth()
    var day = d.getDate()
    return (
        <Container id="register">
            {props.loading ? (
                <center>
                    <h4>{lang.we_are_preparing_ypur_box} ✨</h4>
                    <Spinner size={45} intent="primary" className="pt-6 pb-6" />
                </center>
            ) : (
                <>
                    <center className="mb-6">
                        <h3>{lang.almost_ready}</h3>
                        <p>{lang.a_few_more_details}</p>
                    </center>
                    <Row>
                        <Col xs={12} md={{ size: 6, offset: 3 }}>
                            <form onSubmit={props.SubmitQuizAndRegister}>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Input
                                            label={lang.first_name}
                                            type="first_name"
                                            id="first_name"
                                            required
                                            value={data.first_name}
                                            onChange={props.handleChange}
                                        />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Input
                                            label={lang.last_name}
                                            type="last_name"
                                            id="last_name"
                                            required
                                            value={data.last_name}
                                            onChange={props.handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Input
                                    label={lang.email}
                                    type="email"
                                    id="email"
                                    placeholder="heybeauty@lacajarosa.com"
                                    required
                                    value={data.email}
                                    onChange={props.handleChange}
                                />

                                <Input
                                    label={lang.phone}
                                    type="phone"
                                    id="phone"
                                    required
                                    value={data.phone}
                                    onChange={props.handleChange}
                                />

                                <Input
                                    label={
                                        <>
                                            <i className="low-opacity mr-1 fab fa-instagram"></i> {lang.your_instagram_user} ✨
                                        </>
                                    }
                                    id="instagram_username"
                                    type="text"
                                    value={data.instagram_username}
                                    placeholder="@lacajarosa"
                                    onChange={props.handleChange}
                                />
                                {/* 
                                <div className="form-group">
                                    <label>Fecha de cumpleaños (Dia/Mes/Año):</label>
                                    <NumberFormat
                                        required
                                        id="birthdate"
                                        className="form-control"
                                        format="##-##-####"
                                        placeholder="DD-MM-YYYY"
                                        value={data.birthdate}
                                        onChange={props.handleChange}
                                    />
                                </div> */}

                                <Input
                                    label={lang.password}
                                    id="password"
                                    type="password"
                                    required
                                    value={data.password}
                                    onChange={props.handleChange}
                                />

                                <Checkbox
                                    id={'1'}
                                    required
                                    onChange={(e) => props.handleCheckbox('agree', e)}
                                    label={
                                        <>
                                            {lang.i_have_read}{' '}
                                            <a href="#" target="_blank">
                                                {lang.term_of_service}
                                            </a>
                                            , {lang.the}{' '}
                                            <a href="#" target="_blank">
                                                {lang.privacy_policy}
                                            </a>{' '}
                                            {lang.community_standards}.
                                        </>
                                    }
                                />
                                <center className="mt-6">
                                    <Button primary>
                                        {props.loadingForm ? (
                                            <Loader type="ThreeDots" color="#FFF" height={10} width={100} />
                                        ) : (
                                            lang.create_profile
                                        )}
                                    </Button>
                                </center>
                            </form>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    )
}
const Container = styled.section``
export default Register
