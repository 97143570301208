import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'

// Local imports
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import './static/styles.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import Content from './content'
import UserProvider, { UserConsumer } from './context/UserProvider'

class App extends Component {
    render() {
        return (
            <UserProvider>
                <UserConsumer>
                    {({ state }) => (
                        <BrowserRouter>
                            <Content user={state} />
                        </BrowserRouter>
                    )}
                </UserConsumer>
            </UserProvider>
        )
    }
}

export default App
